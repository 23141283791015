@import '../../variables';

button {
    font-size: 24px;
    font-weight: 400;
    padding: 8px;
    background-color: $color_dark_gray;
    color: $color_sand;
    border: 5px solid $color_dark_crimson;
    outline: none;
    width: 220px;
    margin: 1% 0;
    border-radius: 2px;
    cursor: pointer;
    box-shadow: 1px 1px 0px 2px $color_dark_gray;
    transition: all 0.2s;
    &:hover {
        background-color: darken($color_dark_gray, 5%);
        transform: translateY(1px);
    }
    &:active {
        transform: translateY(1px);
    }
}
