$color_sand: #f4faaf;
$color_dark_sand: #e3d88f;
$color_light_brown: #ccb690;
$color_blue: #74aec6;
$color_medium_brown: #ae8d78;
$color_dark_brown: #8c7d66;
$color_light_gray: #6d7073;
$color_green: #287051;
$color_very_dark_brown: #635751;
$color_dark_crimson: #61444a;
$color_dark_gray: #3a383c;


@mixin desktop {
    @media only screen and (min-width: 1280px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: 600px) and (max-width: 1279px) {
        @content;
    }
}

@mixin mobile {
    @media only screen and (max-width: 599px) {
        @content;
    }
}

 
@mixin boxShadow($h) {
    box-shadow: $h;
    -webkit-box-shadow: $h;
    -moz-box-shadow: $h;
    -ms-box-shadow: $h;
}

@mixin rotate($h) {
    -webkit-transform: rotate($h);
    transform: rotate($h);
    -moz-transform: rotate($h)
}
