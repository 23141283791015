@charset 'UTF-8';

@import url('https://fonts.googleapis.com/css?family=VT323&display=swap&subset=latin-ext');

@import '../variables';


* {
    box-sizing: border-box;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: antialiased;
    image-rendering: pixelated;
}


html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'VT323', monospace;
    background-color: $color_dark_gray;
    background-image: url('/images/wasteland.png');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    color: $color_sand;
}

button {
    font-family: 'VT323', monospace;
    letter-spacing: 0.02em;
}
