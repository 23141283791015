@import '../../variables';

div.endgame-div {
    color: $color_dark_sand;
    background-color: $color_dark_gray;
    // @include boxShadow(1px 2px 3px #fff);
    padding: 0 0 30px 0;

    h1 {
        margin: 0;
        padding-top: 30px;
    }
    
    button {
        margin-left: 10px;
    }

    p, h1, h2 {
        text-align: center;
    }

    h2 {
        padding-top: 25px;
    }
}
