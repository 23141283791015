@import '../../variables';


div.main-game-div {
    
    background-color: $color_dark_gray;
    color: $color_sand;
    position: relative;
    
    div.main-game-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        div.indicatorImages {
            display: flex;
            flex-direction: row;
            margin: 10px;
            div.imageFuel {
                width: 64px;
                height: 64px;
                background-image: url('/images/gauge.png');
                background-position: -192px;
                background-repeat: no-repeat;
                background-size: cover;
    
                div.imageFuelIndicator {
                    width: 64px;
                    height: 64px;
                    background-image: url('/images/gauge.png');
                    background-position: -64px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-origin: border-box;
                }
            }
    
            div.imageCarTemperature {
                width: 64px;
                height: 64px;
                background-image: url('/images/gauge.png');
                background-position: -128px;
                background-repeat: no-repeat;
                background-size: cover;
    
                div.imageCarTemperatureIndicator {
                    width: 64px;
                    height: 64px;
                    background-image: url('/images/gauge.png');
                    background-position: -64px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-origin: border-box;
                }
            }
        }
        
        span.maingame-title {
            text-align: center;
            margin: 0;
            font-size: 36px;
            font-weight: bold;
            padding: 15px 25px;
        }
       
        // MainMenuButton
        button {
            margin: 10px;
            min-width: 80px;
            width: 80px;
            margin: auto 0 auto 10px;
            min-height: 60px;
            max-height: 60px;
            padding: 2px;
            font-size: 32px;
        }
    }


    div.main-view-container {
        display: grid;
        grid-template-columns: 25% 50% 25%;
        grid-template-rows: auto;
        grid-template-areas: 
            "header main footer";
        font-size: 24px;
    }

    div.main-view-left {
        grid-area: header;
        border: 1px solid yellow;
        padding: 10px;
        
        div.statistics-panel {
            display: flex;
            flex-direction: column;
            line-height: 34px;
            margin-top: 20px;
            
            div.stats-part {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                
                span {
                    margin: 0 5px;
                };
            }
        }
    }

    div.main-view-middle {
        grid-area: main;
        padding: 10px;
        border: 1px solid red;
        

        div.events-button-div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            
            button {
                margin: 0 10px;
            }
        }
        

        div.events-panel {
        
            div.history-events-container {
                
                ul.history-events {
                    border: 1px solid blue;
                    max-height: 340px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    min-height: 340px;
                    list-style: none;
                    padding-left: 0;

                    li {
                        padding: 4px 10px;
                    }
                }
            }
        }
    }

    div.main-view-right {
        grid-area: footer;
        border: 1px solid green;
        padding: 10px;

        div.upgrades-panel {
            padding: 0 0 0 0;
        }

        div.map-button-panel {
            button {
                width: 70px;
                max-width: 70px;
            }
        }
    }
}

