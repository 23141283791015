@import '../../variables';


div.splash-screen-main {
    margin: 0;
    color: $color_sand;
    padding: 40px 0;
    text-align: center;
    position: relative;

    div.changelogdiv {
        position: absolute; 
        top: 15%;
        left: 0%;
        width: 100%;
        min-height: 500px;
        z-index: 10;
        text-align: right;

        div.center-content {
            padding: 5px;
            background-color: $color_dark_gray;
            width: 96%;
            max-width: 960px;
            min-height: 500px;
            margin: 0 auto;
            height: 100%;
            border-radius: 2px;
            
            @include tablet {
                width: 80%;
                max-width: 100%;
            }
    
            @include desktop {
                width: 60%;
            }

            button {
                max-width: 50px;
                margin: 5px;
            }

            div.changelog-content {
                max-height: 330px;
                width: 100%;

                div.ziuuu {
                    position: absolute;
                    top: 35%;
                    left: calc(50% - 32px);
                    width: 250px;
                    height: 250px;
                    width: 64px;
                    height: 64px;
                    & div {
                        box-sizing: border-box;
                        display: block;
                        position: absolute;
                        width: 51px;
                        height: 51px;
                        margin: 6px;
                        border: 6px solid #fff;
                        border-radius: 50%;
                        animation: ziuuu-anim 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                        border-color: #fff transparent transparent transparent;
                    }
                    & div:nth-child(1) {
                        animation-delay: -0.45s;
                    }
                    & div:nth-child(2) {
                        animation-delay: -0.3s;
                    }
                    & div:nth-child(3) {
                        animation-delay: -0.15s;
                    }
                }
                p {
                    text-align: center;
                }
                ul {
                    overflow: auto;
                    list-style: none;
                    padding: 5px;
                    li {
                        text-align: left;
                        margin: 10px 0;
                    }
                }
            }
        }
    }

    h1 {
        margin-top: 60px;
        font-size: 4.5em;
        text-shadow: 2px 2px 2px $color_dark_gray;
        // @include mobile {
        //     font-size: 3.5em;
        //     margin-top: 10px;
        // }
    }

    div.splash-buttons {
        display: flex;
        flex-direction: column;
        
        button {
            margin: 15px auto;
        }
    }

    div#registerPopup {
        display: none;
        flex-direction: column;
        position: absolute;
        width: 300px;
        height: 320px;
        top: 50%;
        bottom: 50%;
        left: 50%;
        margin-left: -150px;
        margin-top: -125px;
        
        z-index: 1;
        background-color: $color_light_gray;
        box-shadow: 2px 2px 0px 3px rgba($color_dark_gray, 0.9);

        font-size: 24px;
        border: none;
        border-radius: 1px;

        button {
            background-color: $color_dark_crimson;
            border: none;
            box-shadow: 2px 2px 0px 3px rgba($color_dark_gray, 0.9);
        }

        div.title-and-close {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            text-align: center;
            position: relative;
            button[type="reset"] {
                position: absolute;
                left: calc( 100% - 48px);
                top: calc(0% + 4px);
                max-width: 40px;
                max-height: 40px;
                width: 40px;
                height: 40px;
                margin: 0;
                font-size: 16px;
            }
            span.form-title {
                margin: 10px 10px 20px 10px;
                font-size: 32px;
                text-shadow: 2px 2px 2px $color_dark_gray;
            }
        }

        div#registerform {
            position: relative;
            div.indicator {
                position: absolute;
                top: 44.8%;
                left: calc(50% - 10px);
                text-align: center;
                span {
                    width: 10px;
                    font-size: 28px;
                }
                span.passwordMatchCheck {
                    color: rgb(81, 197, 3);
                }
                span.passwordMatchNoEqual {
                    color: rgb(218, 5, 5);
                }
            }
            div.field {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: baseline;
                margin: 10px 40px;
                border: 3px solid rgba($color_dark_gray, 0.6);
                border-radius: 2px;
                // box-shadow: 2px 2px 0px 3px rgba($color_dark_gray, 0.9);
                span {
                    font-size: 24px;
                    margin: auto 0;
                }
                input {
                    margin: 6px;
                    padding: 6px;
                    max-width: 165px;
                    width: 165px;
                    border-radius: 2px;
                    outline: none;
                    border: none;
                    border: 2px solid rgba($color_dark_gray, 0.8);
                    transition: .25s all;
                    &:active, &:focus {
                        box-shadow: 1px 1px 0px 2px rgba($color_dark_gray, 0.9);
                    }
                }
            }
        }

        button {
            position: relative;
            margin-top: 16px;
        }
    }

    div#loginPopup {
        display: none;
        flex-direction: column;
        position: absolute;
        width: 300px;
        height: 320px;
        top: 50%;
        bottom: 50%;
        left: 50%;
        margin-left: -150px;
        margin-top: -125px;
        
        z-index: 1;
        background-color: $color_light_gray;
        box-shadow: 2px 2px 0px 3px rgba($color_dark_gray, 0.9);

        font-size: 24px;
        border: none;
        border-radius: 1px;

        button {
            background-color: $color_dark_crimson;
            border: none;
            box-shadow: 2px 2px 0px 3px rgba($color_dark_gray, 0.9);
        }

        div.title-and-close {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            text-align: center;
            position: relative;
            button[type="reset"] {
                position: absolute;
                left: calc( 100% - 48px);
                top: calc(0% + 4px);
                max-width: 40px;
                max-height: 40px;
                width: 40px;
                height: 40px;
                margin: 0;
                font-size: 16px;
            }
            span.form-title {
                margin: 50px 10px 20px 10px;
                font-size: 32px;
                text-shadow: 2px 2px 2px $color_dark_gray;
            }
        }

        div#loginform {
            div.field {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: baseline;
                margin: 10px 40px;
                border: 3px solid rgba($color_dark_gray, 0.6);
                border-radius: 2px;
                // box-shadow: 2px 2px 0px 3px rgba($color_dark_gray, 0.9);
                span {
                    font-size: 24px;
                    margin: auto 0;
                }
                input {
                    margin: 6px;
                    padding: 6px;
                    max-width: 165px;
                    width: 165px;
                    border-radius: 2px;
                    outline: none;
                    border: none;
                    border: 2px solid rgba($color_dark_gray, 0.8);
                    transition: .25s all;
                    &:active, &:focus {
                        box-shadow: 1px 1px 0px 2px rgba($color_dark_gray, 0.9);
                    }
                }
            }
        }

        button {
            position: relative;
            margin-top: 16px;
        }
    }
}

@keyframes ziuuu-anim {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
