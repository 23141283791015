@import '../../variables';


div.mainmenu-bg {
    margin: 0;
    padding: 0;
    position: relative;
    background-color: $color_dark_brown;
    color: $color_sand;
    overflow: hidden;
    z-index: 0;
    width: 100%;
    height: auto;
    background-image: url('/images/wasteland.png');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;

    .mainmenu-content {
        div.login-part {
            margin: 8px;
            display: inline-block;
            z-index: 1;
            border-radius: 2px;
            span {
                font-size: 16px;
                font-weight: bold;
            }
        }
        h1.mainmenu-gametitle {
            text-align: center;
            margin: 50px 0px 10px 0px;
            font-size: 64px;
            color: $color_very_dark_brown;
            text-shadow: 2px 1px 3px $color_dark_brown;
        }

        div.buttons-div {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            min-height: 400px;
        }

    }
}
