@import '../../variables';

div.junkyard-menu {
    background-color: $color_medium_brown;
    color: $color_sand;
    
    h1, h2, h3 {
        text-align: center;
    }

    h2.junkyard-title {
        color:violet;
    }

    button {
        margin-left: 10px;
    }
}
